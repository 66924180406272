import React from 'react';
import Loader from "./Loader";

const StartPage = ({data, setIsStarted}) => {
    if (!data) return <Loader/>
    return(
        <div class="quizz__start">

            <h1 className="quizz__title"><span>{data.title.quizz_title_1}</span> <span>{data.title.quizz_title_2}</span></h1>
            <div className="quizz__teasing">{data.teasing}</div>
            <button className="btn-primary btn-default" onClick={setIsStarted}>C'est parti</button>
        </div>
    )
}

export default StartPage;