import {useState} from "react";
import Issue from "./Issue";

const Form = ({data, handleResults, resetForm}) => {
    const [currentStepIndex, setCurrentStepIndex] = useState(0);
    const [formData, setFormData] = useState([]);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const {survey, title} = data;


    /**
     * Handle Form choice
     *
     * @param value
     */
    const handleChoice = (value, step) => {
        const newState = [...formData];
        newState[step] = value;
        setFormData(oldState => newState);
        if (step === currentStepIndex) {
            setCurrentStepIndex(oldState => oldState + 1);
        }

    }

    const validateFormBeforeSubmit = () => {
        if (
            currentStepIndex === survey.length &&
            formData.length === survey.length
        ) {
            return true
        }
        return false;
    }

    const handleSubmit = () => {
        handleResults(formData);
        setIsSubmitted(true);
    }

    const handleReset = () => {
        resetForm();
        setFormData(oldState => []);
        setCurrentStepIndex(0);
    }

    return (
        <>

            <div className="quizz__form">
                <div className="quizz__form-inner">
                    <h2><span>{title.quizz_title_1}</span> <span>{title.quizz_title_2}</span></h2>
                    <p className="quizz__paragraph">Sélectionnez une première réponse pour afficher la suivante</p>
                    {survey && survey.map((issue, stepIndex) => (
                        <Issue
                            key={stepIndex}
                            issue={issue}
                            stepIndex={stepIndex}
                            currentStepIndex={currentStepIndex}
                            handleChoice={handleChoice}
                            formData={formData}
                            stepIsCompleted={formData[stepIndex] !== undefined}
                        />
                    ))}
                    <div className="quizz__actions">
                            <button onClick={() => handleSubmit()}
                                    className="btn-default btn-primary" disabled={!validateFormBeforeSubmit()}>{isSubmitted ? 'Mettre à jour les résultats' : 'Afficher les résultats'}</button>
                            {validateFormBeforeSubmit() && <button className="btn-default btn-blue-border" onClick={() => handleReset()}>Réinitialiser</button>}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Form;