import React, {useEffect, useState} from 'react';

const Issue = ({issue, handleChoice, stepIndex, currentStepIndex, formData, stepIsCompleted}) => {
    const [answers, setAnswers] = useState(null);

    const updateAnswers = () => {
        //Loop on conditions
        for (let i = 0; i < issue.conditionnal_answers.length; i++) {
            let condition = issue.conditionnal_answers[i];
            let issueIndex = issue.issue_number - 1;

            // If there is a condition, we get the result of the form for the concerned answer
            if (condition) {
                const selectedAnswer = formData[issueIndex];

                if (selectedAnswer) {

                    for (let j = 0; j < condition.selected_answers.length; j++) {
                        if (condition.selected_answers[j].value === selectedAnswer) {
                            setAnswers(condition.suggested_answers)
                            break;
                        }

                    }
                }

            }

        }
    }

    useEffect(() => {
        if (issue.display_conditionnal_answers === true) {
            updateAnswers();
        } else {
            setAnswers(issue.answers);
        }
    }, [formData]);


    return (
        <div className={`quizz__issue-container ${(stepIndex <= currentStepIndex || stepIsCompleted) && 'is-active'}`}>
            <div className="quizz__issue">{issue.quizz_issue}</div>

            {(stepIndex === currentStepIndex || stepIsCompleted) && answers && answers.map((answer, index) => (
                <button key={index} data-term={answer.value}
                        className={`quizz__answers ${answer.value === formData[stepIndex] ? ' is-active ' : ' '} `}
                        onClick={() => handleChoice(answer.value, stepIndex)}
                >
                    {answer.label}
                </button>
            ))}
        </div>
    )
}
export default Issue;