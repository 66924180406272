import './App.css';
import React, {useEffect, useState} from "react";
import StartPage from "./components/StartPage";
import FormContainer from "./components/FormContainer";
import Detail from "./components/Detail";

const App = (props) => {
    const [domain, setDomain] = useState(null);
    const [formFields, setFormFields] = useState(null);
    const [results, setResults] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isStarted, setIsStarted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [displayDetail, setDisplayDetail] = useState(false);
    const [detail, setDetail] = useState({});

    useEffect(() => {
        const container = document.getElementById('quizz__module');
        if (container) {
            setDomain(container.dataset.domain);
        }
    }, [])


    /**
     * Get survey
     *
     * @returns {Promise<null>}
     */
    const fetchFormFields = async () => {
        let data = null;
        if (domain) {

            const response = await fetch(`${domain}/${process.env.REACT_APP_FORM_PATH}`)
            data = await response.json();

            setFormFields(data);
        }

        return data
    }

    useEffect(() => {
        fetchFormFields();

    }, [domain])


    /**
     * Get results of survey
     *
     * @param terms
     * @returns {Promise<void>}
     */
    const getResults = async (terms) => {
        setIsLoading(true);
        const query_string = terms.join(',');
        const response = await fetch(`${domain}/${process.env.REACT_APP_FORM_PATH}/choices=${query_string}`);
        const data = await response.json();

        setResults(data);
        setIsLoading(false);
        setIsSubmitted(true);
    }

    const resetForm = () => {
        setResults(null);
        setIsSubmitted(false);
    }

    return (
        <>
            {isStarted && formFields ?
                <FormContainer isSubmitted={isSubmitted} isStarted={isStarted} domain={domain} isLoading={isLoading}
                               resetForm={resetForm} data={formFields} handleResults={getResults} results={results}
                               setDisplayDetail={setDisplayDetail} setDetail={setDetail}
                /> :
                <StartPage data={formFields} setIsStarted={setIsStarted}/>}
            {displayDetail && <Detail data={detail} setDisplayDetail={setDisplayDetail}/>}
        </>
    );
}

export default App;
