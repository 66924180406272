import React, {useEffect, useState} from 'react';

const Detail = ({data, setDisplayDetail}) => {

    const [members, setMembers] = useState([]);

    const getMembers = () => {
        const members = [];
        for (let i = 0; i < data.section_members; i++) {
            let obj = {
                name: data[`section_members_${i}_name`],
                job: data[`section_members_${i}_job`]
            }
            members[i] = obj;
        }
        setMembers(members);
    }

    useEffect(() => {
        getMembers();
    }, [])

    return (
        <div className="quizz__detail-outer">
            <div className="quizz__detail">
                <div className="quizz__detail-picture"><img src={data.api_picture.guid} alt=""/></div>
                <div className="quizz__detail-content">
                    <div className="quizz__detail-title">{data.post_title}</div>
                    <div dangerouslySetInnerHTML={{__html: data.description}}  className="quizz__detail-description"/>
                    {(data.location_street || data.location_zipcode || data.location_city) && (
                        <div className="quizz__detail-location">
                            <h3>Lieu de pratique</h3>
                            {data.location_street} <br/>
                            {data.location_zipcode} {data.location_city}
                        </div>
                    )}
                    {members && members.map((member, index) => (
                        <div key={index}  className="quizz__detail-members">
                            <h3>{member.job}</h3>
                            <div>{member.name}</div>
                        </div>
                    ))}
                    {(data.contact_email || data.contact_phone) && (
                        <div className="quizz__detail-contacts">
                            <h3>Coordonnées</h3>
                            {data.contact_email && `Mail : ${data.contact_email}`} <br/>
                            {data.contact_phone && `Tél : ${data.contact_phone}`} <br/>
                        </div>
                    )}
                    {data.display_button && (
                        <a href={data.bouton_button_type === 'link' ? data.button.file.url : data.button_website}
                           target="_blank" rel="noreferrer" className="btn-default btn-primary">{data.button_button_label}</a>
                    )}
                </div>
                <button className="quizz__detail-close" onClick={() => setDisplayDetail(false)}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="13.22" height="13.22" viewBox="0 0 13.22 13.22">
                        <g id="Groupe_81" data-name="Groupe 81" transform="translate(-1320.39 -361.39)">
                            <line id="Ligne_2" data-name="Ligne 2" x2="14.696" transform="translate(1321.804 362.804) rotate(45)" fill="none" stroke="#4050ff" stroke-linecap="round" stroke-width="2"/>
                            <line id="Ligne_13" data-name="Ligne 13" x2="14.696" transform="translate(1332.196 362.804) rotate(135)" fill="none" stroke="#4050ff" stroke-linecap="round" stroke-width="2"/>
                        </g>
                    </svg>
                </button>
            </div>
        </div>
    )
}

export default Detail;