import React from "react";
import ResultItem from "./ResultItem";
import Loader from "./Loader";

const Results = ({results, domain, isLoading, setDisplayDetail, setDetail}) => {
    if (isLoading) {
        return <Loader/>
    }
    return (
        <div className="quizz__results">
            {results && (
                <>
                    <h2>Nos suggestions</h2>
                    <div className="quizz__grid">
                        {results && results.length > 0 ? results.map((result, index) => <ResultItem key={index}
                                                                                                    result={result}
                                                                                                    domain={domain}
                                                                                                    setDisplayDetail={setDisplayDetail}
                                                                                                    setDetail={setDetail}/>) : (
                            <p className="quizz__no-results">Il n'y a pas de résultat</p>
                        )}
                    </div>
                    <div className="quizz__archive-links">
                        <a href="/sections-et-secteurs/sections-sportives/">Tous les sports</a>
                        <a href="/sections-et-secteurs/secteurs-dactivites/">Toutes les sections</a>
                    </div>
                </>
            )}
        </div>
    )
}

export default Results;