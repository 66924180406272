import Form from "./Form";
import Results from "./Results";
import Loader from "./Loader";

const FormContainer = ({data, isSubmitted, resetForm, handleResults, results, domain, isLoading,setDisplayDetail, setDetail}) => {
    return (
        <>
            <div className="quizz__picture">
                {data && <img src={data.picture.url}/>}
            </div>
            <div className="quizz__container">
                <Form resetForm={resetForm} data={data} handleResults={handleResults}/>
                {isLoading ? <Loader/> : isSubmitted &&
                    <Results results={results} domain={domain} isLoading={isLoading}
                             setDisplayDetail={setDisplayDetail} setDetail={setDetail}
                    />}
            </div>
        </>
    )
}

export default FormContainer;