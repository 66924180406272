import React from "react";

const ResultItem = ({result, domain, setDetail, setDisplayDetail}) => {

    const getResultDetail = async () => {
        setDisplayDetail(true);
        setDetail(result);
    }

    return (
        <div className="card with-link" onClick={getResultDetail}>
            <div className="card__inner">
                <div className="card__picture-wrapper">
                    <div className="card__picture">
                        <img src={result.api_picture.guid} alt=""/>
                    </div>
                </div>
                <div className="card__content">
                    <div className="card__title">{result.post_title}</div>
                    <span className="card__link"></span>
                </div>
            </div>
        </div>
    )
}

export default ResultItem;